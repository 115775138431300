@charset "UTF-8";


// Spacer-Variablen
$bs_spacer_1: get_bs_spacer(1);
$bs_spacer_2: get_bs_spacer(2);
$bs_spacer_3: get_bs_spacer(3);
$bs_spacer_4: get_bs_spacer(4);
$bs_spacer_5: get_bs_spacer(5);


// DEV STUFF /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Breakpoint-Indikator
// .bp_wrapper { display: none; }
.bp_wrapper { position: fixed; top: .4rem; left: $bs_spacer_3; height: $bs_spacer_5; width: $bs_spacer_5; border-radius: 50%; background-color: white; border: 2px solid orange; font-size: 1.2rem; opacity: .5; z-index: 2000; };
.bp_wrapper div { width: 100%; text-align: center; padding-top: $bs_spacer_2; };
// Containerrahmen
// div[class*=container] { border: 1px solid red; }
// div[class*=row] { border: 1px solid blue; }
// div[class*=col] { border: 1px solid green; } 


html { scroll-behavior: smooth; }

.cmp_content *,
aside * { hyphens: auto; }


.frm_container_maxw { 
	@include make-container(); max-width: 1020px; @include container_padding(); 
	.content_wrapper { @include make-row(); }
}


// Head Image /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_head_img { overflow: hidden; position: relative; 
	@include media-breakpoint-up(sm) {
		height: 30vw;
	}
	@include media-breakpoint-up(lg) {
		height: 27vw;
	}
	@include media-breakpoint-up(xl) {
		height: 20.4rem;
	}
}
.frm_head_img img {
	@include media-breakpoint-up(lg) {
		top: -1.5rem; position: relative;
	}
}
.frm_head_img .credits { position: absolute; bottom: .2rem; right: 1.5rem; padding: 0 .2rem; font-size: 2vw; background-color: rgba(255, 255, 255, 0.5); 
	@include media-breakpoint-up(md) {
		font-size: .8rem;
	}
}
.frm_head_marker.container-lg { position: relative; z-index: 1; }
.frm_head_marker img { position: absolute; top: -1.5rem; width: 38vw; 
	@include global_box_shadow(); 
	@include media-breakpoint-up(sm) {
		max-width: 14rem;
	}
	@include media-breakpoint-up(md) {
		max-width: 16rem;
	}
	@include media-breakpoint-up(lg) {
		max-width: 18rem;
	}
}


// TODO: AUF DER SEITE 2X VORHANDEN MIT GLEICHER ID!!!! ##################################################################################################
// Navi left/mobile Navi Bottom



//natur/quellen/index1.htm
.frm_btm_nav_m { 
	position: relative; margin-bottom: $bs_spacer_5; background-color: get_thm_col(basis,18p);
	h2 { @include heading_marke(); @include heading_marke_color(); transform: translateY(0); }
	@include media-breakpoint-up(lg) { padding: 0; }
	a:link { display: block; } 
	ul.lvl1 { margin-bottom: 0; border-bottom: 3px solid white; }
	ul.lvl1 a { font-size: 1.1rem; line-height: 1.3rem; }
	ul.lvl1 a.thema { 
		margin: 0 1rem; padding: .8rem 0; text-align: left; font-weight: bold; 
		&.thborder { border-bottom: 2px solid transparent; }
	}
	ul.lvl2 { margin: $bs_spacer_3; }
	ul.lvl3 { border-left: 1px solid get_thm_col(basis,85p); padding-left: $bs_spacer_2; margin: .35rem 0; }
	ul.lvl4 { padding-left: .7rem; margin: .35rem 0; }
	h2 { display: none; }
	a:link,
	a:visited { @include funktionslink_a(); }
	a:hover,
	a:active { @include funktionslink_hover(); }
}
// Abhängig vom Bereich
@each $str_color_id in $thm_arr_themen {
	body.#{$str_color_id} ul.lvl1 a.thema.thborder {
		border-color: get_thm_col($str_color_id);
	}
} 

// Abhängig vom Bereich
@each $str_color_id in $thm_arr_themen {
	body.#{$str_color_id} .frm_btm_nav_m h2 {
		@include heading_marke_color($str_color_id);
	}
} 
.frm_btm_nav_m_da { margin-top: 0; margin-bottom: $bs_spacer_5; }
.frm_navbar_m { 
	background-color: get_thm_col(basis,18p); @include global_box_shadow(); 
	.btn { 
		font-size: 1.4rem; 
		padding: .5rem 0;
		@include media-breakpoint-up(sm) { font-size: 1.25rem; }
		i { padding-right: .3rem; }
	}
}


// Klasse für globalen Schatten /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_shadow { box-shadow: 0 0 20px rgba(0,0,0,.3); } 


// Site mit fester Breite /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_maxw_wrapper { /* overflow: hidden; */ max-width: $thm_site_max_width; padding: 0; }

  

// Padding Container Class /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_container_padding { @include container_padding(); }


// Seitennavigation Screenreader /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_anchors { @include visually-hidden; }


// Desktop-/Mobile-Text Elemente anzeigen /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.show_mobile { display: block; @include media-breakpoint-up(md) { display: none	} }
.show_desktop { display: none; @include media-breakpoint-up(md) { display: block; } }

// Klassen: .thm_col_<type>_basis_85p /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@each $str_color_id, $arr_values in $thm_arr_colors {
	@if map-has-key($thm_arr_colors, $str_color_id) {
		@each $ton-key, $ton-value in $arr_values {
			@if $ton-key == basis {
				.thm_col_co_#{$str_color_id} {
					color: $ton-value !important;
				}
				.thm_col_bg_#{$str_color_id} {
					background-color: $ton-value !important;
				}
				.thm_col_bd_#{$str_color_id} {
					border-color: $ton-value !important;
				}
			} @else {
				.thm_col_co_#{$str_color_id}_#{$ton-key} {
					color: $ton-value !important;
				}
				.thm_col_bg_#{$str_color_id}_#{$ton-key} {
					background-color: $ton-value !important;
				}
				.thm_col_bd_#{$str_color_id}_#{$ton-key} {
					border-color: $ton-value !important;
				}
			}
		}
	}
	@else
	{
		@warn "Unbekannter Index `#{$str_color_id}` in Map $thm_arr_colors.";
	}
}


// Header /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Wappen, Burger
.frm_offcanvas {
	.frm_menu { 
		.btn { 
			padding: .6rem 1.1rem; 
			@include media-breakpoint-up(sm) {
				padding: .7rem 1.5rem;
			}
			@include media-breakpoint-up(md) {
				padding: .7rem 1.2rem;
			}
			@include media-breakpoint-up(xl) {
				padding: .7rem 1.4rem;
			}
		} 
		.btn .fa-xmark { font-size: 2.5rem; }
		.btn .button_label { 
			display: none;
			margin-top: $bs_spacer_2;
			margin-left: $bs_spacer_2;
			vertical-align: top; 
			@include media-breakpoint-up(md) {
				display: inline-block;
			}
		}
	}

	// Suchfeld
	.input-group input { width: 45vw; max-width: 36rem; }
}





// Inhaltsbereich /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_page { 
	word-break: break-word; 
	margin-bottom: $bs_spacer_5;
	// Abstände Homepage/Themenseite/Navi Links
	&.home { padding: 0; }
	// Width-Fix für Navi-Links
	.tmp_width_padding { 
		@include media-breakpoint-up(xl) { 
			padding-right: 2.8rem;
		}
	}
}


// Offcanvas-Menü /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_offcanvas.offcanvas { bottom: initial; }
.frm_offcanvas.offcanvas.offcanvas-top { height: auto; }
.frm_offcanvas.offcanvas .offcanvas-header { background-color: #fff; }
.frm_offcanvas.offcanvas .frm_maxw_wrapper { overflow-y: auto; overflow-x: hidden; } 
.frm_offcanvas.offcanvas .frm_oc.first { border-bottom: 1px solid get_thm_col(basis); 
	@include media-breakpoint-up(md) {
		border-bottom: none; border-right: 1px solid get_thm_col(basis);
	}
}
.frm_offcanvas.offcanvas .frm_oc.second { 
	// .nav-item i { transform: translate(0,-5%); }
	@include media-breakpoint-up(md) {
		border-right: 1px solid get_thm_col(basis);
	}
} 
.frm_offcanvas.offcanvas a.active { font-weight: bold; color: get_thm_col(basis); } 
.frm_offcanvas.offcanvas a:link,
.frm_offcanvas.offcanvas a:visited { @include funktionslink_a(); }
.frm_offcanvas.offcanvas a:hover,
.frm_offcanvas.offcanvas a:active { @include funktionslink_hover(); }
.frm_offcanvas.offcanvas ul li a span { color: get_thm_col(basis,85p); }
.frm_oc.fourth {
	.nav {
		justify-content: center;
		margin-left: auto; margin-right: auto; 
		padding-top: $bs_spacer_5;
		.nav-item a { padding: 0 $bs_spacer_2; }
	} 
} 


// Überschriften /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
h1, h2, h3, h4, h5, h6 { clear: both; }
.frm_page_h1 { font-family: $h1_font_family; font-weight: $h1_font_weight; color: $h1_col; }
.cmp_content {
	h1 { font-family: $h1_page_font_family; font-weight: $h1_page_font_weight; color: $h1_page_col; }
	h2 { font-family: $h2_font_family; font-weight: $h2_font_weight; color: $h2_col; }
	h3 { font-family: $h3_font_family; font-weight: $h3_font_weight; color: $h3_col; }
	h4 { font-family: $h4_font_family; font-weight: $h4_font_weight; color: $h4_col; }
	h5 { font-family: $h5_font_family; font-weight: $h5_font_weight; color: $h5_col; }
	h6 { font-family: $h6_font_family; font-weight: $h6_font_weight; color: $h6_col; }
}


// Links /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_page ul.linkliste a:visited { color: $thm_link_col_visited; }


// Footer /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.frm_footer {
	font-size: 1.4rem;
	background-color: get_thm_col(basis);
	.footer_to_top a { width: 100%; padding: $bs_spacer_3 0; }
	.frm_footer_fat {
		padding: $bs_spacer_4 0;
		@include media-breakpoint-up(sm) { padding: $bs_spacer_5 0; }
		h2, a, i, span { color: #fff; }
		.ft_bereich
		{
			margin-bottom: $bs_spacer_5;
			&.th
			{ 
				.item { 
					margin-top: $bs_spacer_2; 
					margin-bottom: $bs_spacer_2;
				}
				.thm_marker { width: .3rem; }
			}
			&.kat
			{ 
				--bs-gutter-y: #{$bs_spacer_4};
				h2 { font-size: 1rem; padding-bottom: .7rem; border-bottom: 2px solid get_thm_col(basis,50p); }
				ul { flex-direction: column; line-height: 2.5rem; }
			}
			&.sm
			{
				padding: $bs_spacer_2 0;
				text-align: center;
				a { padding-left: $bs_spacer_4; }
			}
			&.meta
			{
				padding-top: $bs_spacer_3;
				border-top: 2px solid #a9b6bf;
				.nav .nav-item a { padding: 0 $bs_spacer_2 }
				@include media-breakpoint-up(lg) { padding-left: $bs_spacer_3; padding-right: $bs_spacer_3; }
				ul { justify-content: center; }
			}
		}
	}
	// Footer Minimal
	.frm_footer_fat.minimal {
		.ft_bereich { margin-bottom: 0; }
		.ft_bereich.insel_meta {
			margin-bottom: $bs_spacer_2;
			@include media-breakpoint-up(md) { margin-bottom: 0; }
			ul { justify-content: center; }
		}
		.ft_bereich.insel_sm {
			text-align: left;
			@include media-breakpoint-up(md) { text-align: center; }
			a { padding-left: 1.5rem; }
		}
	}
}

// Glossar augeblendet -> Tooltipp
.cmp_compact.glossar { display: none; }
.glossar_begriff, abbr { text-decoration: underline dotted; font-style: italic; cursor: help; }

// Formulare im Content
// Dyn Forms
.dyn_form .dyn_form_dsgvo_container { height: 318px; overflow: auto; margin-top: 25px; }
.dyn_form .dyn_form_dsgvo_container h2 { margin-top: 0; }
.dyn_form_feedback_box { margin-top: 1em; padding: $bs_spacer_2 0; border-radius: 0; }
.dyn_form_feedback { display: block; font-weight: bold; color: get_thm_col(basis_rot); }
.dyn_form_pflichtfeld { color: #006666; }
#dyn_fe_check_hp { display: none !important; visibility: hidden !important }
.dyn_form_charcounter { color: #777777; margin-top: 3px; font-size: .9em; }
.dyn_form_charcounter span { color: #db7c00; font-weight: bold; text-align: center; }
.dyn_form_charcounter span.limit { color: #ff1414; }
// Dyn_Forms-Captcha
#captcha_image { margin: .3em 0 .3em 0; }
// CMU-Formulargenerator (/global/script/cmu_formgen/fg.js)
.dyn_form_element { margin-bottom: $bs_spacer_3; }

// Hinweistext
.hinweis { color: get_thm_col(hinweis); font-weight: bold; }
