@charset "UTF-8";


// HP-Compacts /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.hp_compact_container { 
    @include make-row();
    .hp_compact_item {
        margin-bottom: $bs_spacer_3;
        @include make-col-ready(); 
        @include media-breakpoint-up(md) { @include make-col(6); }
        @include media-breakpoint-up(lg) { @include make-col(4); margin-bottom: 0; }
    }
}


// Messwert-Tabelle mit farblichen Markern /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Farbmarker
$arr_lueb_color: (
    'lueb_dblau':        #2775c2,
    'lueb_mblau':        #65c9fd,
    'lueb_hblau':        #98fffe,
    'lueb_gelb':         #fcfc98,
    'lueb_orange':       #fe9834,
    'lueb_rot':          #da2d2d
);
$arr_fladis_color: (
    'fladis_dblau':      #0078ff,
    'fladis_mblau':      #64d2ff,
    'fladis_hgruen':     #bee68c,
    'fladis_gelb':       #fcfc98,
    'fladis_orange':     #fe9834,
    'fladis_rot':        #da2d2d
);
$arr_ifr_color: (
    'ifr_weiss':         transparent,
    'ifr_ausfall':       transparent,
    'ifr_unterhalb':     #D7D7D7,
    'ifr_normal':        #b2ff8f,
    'ifr_anormal':       #FFF600,
    'ifr_nschlag':       #72b2f1
);
.cnt_mw_table {
    td {
        min-height: 4rem;
        @media (min-width: get_bp('sm')) { min-height: 0; }
    }
    .mw_data {
        .values { 
            @media ( min-width: get_bp('sm')) { padding: 0; }
        }
        .index_td { 
            width: 5rem;
            &.narrow { border-right: $bs_spacer_2 solid transparent; }
            border-right: $bs_spacer_4 solid transparent;
            word-break: keep-all;
            text-align: right;
            a, span { display: block; padding: 0.2rem 0.5rem; }
            @media ( min-width: get_bp('sm')) { width: 100%; }
        }
    }
}

.mw_no_komponent { background: repeating-linear-gradient(45deg,#eeeeee,#eeeeee 5px,#fff 5px,#fff 10px); }

@each $str_color_id, $str_value in $arr_lueb_color {
    .#{$str_color_id} { border-color: $str_value !important; }
}
@each $str_color_id, $str_value in $arr_fladis_color {
    .#{$str_color_id} { border-color: $str_value !important; }
}
@each $str_color_id, $str_value in $arr_ifr_color { 
    .#{$str_color_id} { border-color: $str_value !important; }
}

.cnt_mw_legende {
    .row { margin-bottom: $bs_spacer_2; }
    .col div { border-left: $bs_spacer_4 solid white; margin-bottom: $bs_spacer_2; padding-left: $bs_spacer_2; }
}
.cnt_tbl_legende .lueb_color { border-left: $bs_spacer_4 solid white; }

.lueb_ueberschreitung { background-color: #ffcfbf; }
// .maxborder { border: 2px solid gray; }

// Publikationen -> Libraries
.right_teaser.publikationen { text-align: center; }
.right_teaser.publikationen h1 { text-align: left; }
.right_teaser.publikationen a { display: block; }
.right_teaser.publikationen p { padding-top: .3rem; }


// IfR-Messwerte /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.ifr_mini_legende { margin: 2rem 0; }
.ifr_mini_legende div { border-left: $bs_spacer_4 solid white; margin-bottom: $bs_spacer_2; padding-left: $bs_spacer_2; }
.ifr_mini_legende div.ifr_unterhalb { border-color: #D7D7D7; }
.ifr_mini_legende div.ifr_anormal { border-color: #FFF600 }
.ifr_mini_legende div.ifr_normal { border-color: #b2ff8f; }
.ifr_mini_legende div.ifr_nschlag { border-color: #72b2f1; }
td.ifr_unterhalb { background-color: #D7D7D7; }
td.ifr_anormal { background-color: #FFF600 }
td.ifr_normal { background-color: #b2ff8f }
 


.ui-tooltip.messnetz_tt { padding: 5px !important; background-color: white !important; font-size: .75em !important; }
.ui-tooltip.messnetz_tt h3 { margin: 0 0 4px 0; }
.ui-tooltip.messnetz_tt table { margin: 0 !important; border: 1px solid gray; }

.crosshair { cursor: crosshair !important; }




// Custom Combobox 
.ui-widget { font-family:inherit !important; font-size:1em !important; }
ul.ui-menu,  li.ui-menu-item { list-style-image:none !important; list-style-type: none !important; font-family:inherit !important; font-size:0.9em !important; }
.custom-combobox { position: relative !important; display: inline-block !important; width: 100%; }
.custom-combobox-toggle { position: absolute !important; top: 0 !important; bottom: 0 !important; margin-left: -1px !important; padding: 0 !important; width:2em !important; border: 1px solid #ccc !important; }
.custom-combobox-input { margin: 0 !important; padding: 0.375rem 2.25rem 0.375rem 0.75rem; border: 1px solid #DDD4DB !important; box-shadow: 2px 2px 5px #CCCCCC inset; width: 80%; }
.custom-combobox-input:focus { box-shadow: 0px 0px 15px 4px rgba(253,196,0,.15) !important; border: 1px solid #fdc400 !important; }
.custom-combobox-input:disabled, .custom-combobox-input[readonly] { color:#DDD4DB !important; background-color:#f2f2f2 !important; color: #555 !important; }
.custom-combobox .ui-state-default { font-weight:inherit !important; }
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button { font-family:inherit !important; }
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default{ color:inherit !important; }
.ui-menu-item-wrapper.ui-state-active { border: none !important; background-image:none !important; background-color:#fff !important; font-weight:normal !important; margin:0 !important; color:#000 !important; width:100%; overflow:hidden; }
.ui-menu-item-wrapper:hover, .ui-menu-item-wrapper:focus, .ui-menu-item-wrapper:active { box-shadow: 0px 0px 10px 2px #C5C5C5 !important; border: none !important; background-image:none !important; background-color:#fff !important; font-weight:normal !important; margin:0 !important; color:#000 !important; width:100%; overflow:hidden; }
.ui-autocomplete.ui-menu { border:1px solid #ccc !important; background-color:#fff !important; }
.ui-autocomplete { max-height: 300px; overflow-y: auto !important; overflow-x: hidden !important; z-index:1000 !important; }
.ui-widget-content { background: none !important; border: none !important; }

div span.custom-combobox * { border-radius: 0; }

// Imagemap Hover 
.svg_map_wrapper { position: relative; }
.svg_map_overlay { position: absolute; top: 0; left: 0; pointer-events: none; z-index: 100; }
.svg_map_marker { stroke: #cc7b17; stroke-width: 3; z-index: 101; fill:rgba(253, 196, 0, 0.6); }

// Geodatendienste
.geodatendienste_button_heading {@extend .mb-1; }
.geodatendienste_detail .dienste_tools{@extend .mb-4; }