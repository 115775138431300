// Print-Styles /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media print 
{
    * { color: #000 !important; box-shadow: none !important; text-shadow: none !important; background: transparent !important; }
    html { background-color: #fff; width:100%;}
    .linkliste .extern a[href]:after { content: " (" attr(href) ") "; }
    #cmp_content a[href] { font-weight: bold; text-decoration: underline; color: #06c; border: none; page-break-inside:avoid;}
    a[href^="javascript:"]:after, a[href^="#"]:after, a[href$=".jpg"]:after, a[href$=".jpeg"]:after, a[href$=".png"]:after, a[href$=".gif"]:after { content: ""; }
    abbr[title]:after { content: " (" attr(title) ")"; }
    h1, h2, h3, h4, h5, h6 { page-break-after:avoid; page-break-inside:avoid;}
    div[class^="content_zoom_"] img, div[class^="content_zoom_"], div.content_zoom_img_seitenbreite, div.content_zoom_img_seitenbreite img, #cmp_content img { max-width: 100% !important; page-break-inside: avoid !important; break-inside: avoid !important; }
    .content_more_info {box-decoration-break: clone; word-break:break-all;}
    .cmp_marker_header .cmp_navbar {margin-bottom:0 !important;}
    .cmp_marker_header .cmp_marker {position:static !important;}
    .cmp_marker_header .cmp_marker img {top: 8px !important; width: 145px !important;}
    #cmp_marker_header {margin-bottom: 50px !important;}
    #cmp_sidebar_left {margin-top:210px; float:none !important; clear:both;}
    // Always open all accordion items when printing
    .accordion-collapse { display: block; }
}





