@charset "UTF-8";

// Bisherige Formate oder deren Anpassung */
// Elemente für NoJS ein-/ausblenden
.js { display: none; }
.clear_left { clear: left; }



// Publikationen aus Shop
.content_teaser .imgcontainer { width: 80px; height: 100px; float:left; }
.imgcontainer { position:relative; display: inline-block; background: #f5f5f5; background: linear-gradient(135deg, #f5f5f5 0%,#d6d6d6 100%); }
.right_teaser.publikationen .imgcontainer { width: 136px; height: 181px; }
.imgcontainer.dyn img { position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto; max-height: 80%; max-width: 74%; box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.5); }
// Workaround, weil die automatisch generierten diesen Abstand unten haben!
.imgcontainer.dyn { top: 7px; }

.right_teaser.ausstellung { text-align: center; }
.right_teaser.ausstellung img { width: 118px; margin: $bs_spacer_3 0; }

.right_teaser.faq { text-align: center; }

.right_teaser.rss { position: relative; }



span.link_mail { 
	background-image: url(/global/lib/fontawesome_svg/envelope-sharp-regular.svg); 
	background-repeat: no-repeat; 
	background-size: $bs_spacer_3; 
	padding-left: 1.5em; 
	background-position: 3px 1px;
}



// Eingebettete Kartendienste
.webgiscontainer {
	width: 100%;
	height: 500px;
	border: 2px solid #d3d8df;
	height: auto;
}


// Clear
.clear_both { clear: both; }


// Pressemitteilungen /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.psm_home_hoch { height: 108px !important; width: auto !important;}
.psm_home_quer {width:192px !important;height: 108px !important;}
.pm_container_archiv:nth-of-type(odd) {background-color: #ebf3f5 !important;} 

// Pressefotos /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.pm_picturecontainer { 
	height: 130px; width: 144px; float: left; text-align: center; margin-right: 10px; margin-bottom: 10px; border-left: solid #e1e1e1 2px; border-top: solid #e1e1e1 2px; border-right: solid #747474 2px; border-bottom: solid #747474 2px; padding-top:14px;
	img { width: 120px; height: 90px; }
}
.pm_picturecontainer_title { margin-top: 5px; }
.pm_foto_hoch { position: relative; top: 12px; }
.pm_foto_quer { position: relative; top: 26px; }
div.pm_foto_hoch, div.pm_foto_quer { margin-top: 3px; }
.content_teaser_pm .content_zoom_img_links img { width: 164px; }
.psm_detail_foto { width: 535px; font-weight: .9rem; }

// Detailansicht /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.pm_content.pm_preview {background-image: url("/style/layout/pm_preview.png"); background-repeat: no-repeat; background-position: 100px 40px;}
// .pm_h2 { font-size: 130% !important; }
// #pm_kat { font-size: 80%; font-weight: bold; color: #888; }
#pm_detail_foto_hoch { font-size: .80em; float: right; margin: 5px 0 0 11px; width: 128px; }
#pm_detail_foto_quer, #pm_detail_foto_text { font-size: .95em; float: right; margin: 5px 0 $bs_spacer_2 $bs_spacer_4; width: 170px; }
#pm_detail_foto_hoch img { width: 128px; }
#pm_detail_foto_quer img { width: 170px; }
// #pm_footer { border-top: 1px solid gray; margin-top: 18px; }
// #pm_footer p { font-size: 85%; }
.pm_info_border { border: solid black 1px; padding: 5px; text-align: left; background: #f2fbff; }
.pm_info_border h3 { margin-top: 5px; margin-bottom: 10px; color: #6f6f6f; }
