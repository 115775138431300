@charset "UTF-8";

// Farben /////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Array: Colors
$thm_arr_colors: (
    basis: (
        basis: #526e7f,
        85p: #6c8492,
        50p: #a9b6bf,
        38p: #bdc8ce,
        23p: #d7dee2,
        18p: #e0e5e8
    ),
    basis_gelb: (
        basis: #fdc400,
        18p: #fff4d1
    ),
    basis_gruen: (
        basis: #75b726,
        18p: #e6f2d8
    ),
    basis_rot: (
        basis: #b61a31,
        18p: #f2d6da
    ),
    link: (
        basis: #00649e
    ),
    link_hover: (
        basis: #3089e7
    ),
    link_visited: (
        // basis: #00649e
        basis: #3d2552 
    ),
    link_funktion: (
        basis: #3e5461
    ),
    bildunterschrift: (
        basis: #4d4d4d
    ),
    border: (
        basis: #dfdfdf
    ),
    hinweis: (
        basis: #e4780a
    ),
    h1: (
        basis: #000
    ),
    h2: (
        basis: #000
    ),
    h3: (
        basis: #000
    ),
    h4: (
        basis: #000
    ),
    h5: (
        basis: #000
    ),
    h6: (
        basis: #000
    ),
    abfall: (
        basis: #ae0049
    ),
    altlasten: (
        basis: #657465
    ),
    analytik_stoffe: (
        basis: #283e85
    ),
    boden: (
        basis: #986219
    ),
    energie: (
        basis: #933782
    ),
    geologie: (
        basis: #773120
    ),
    klima: (
        basis: #e4780a
        // basis: #fc8b17
    ),
    laerm: (
        basis: #3e396b
    ),
    luft: (
        basis: #006f77
    ),
    natur: (
        basis: #627E1B
        // basis: #739521
    ),
    strahlung: (
        basis: #b5291d
    ),
    wasser: (
        basis: #2077B1
        // basis: #0089cf Neu
        // basis: #2484c6 Alt
    )
);

// Funktionen /////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme Colors
// Z.B.: background-color: get_thm_col(basis, 85p);
@function get_thm_col($str_color_id, $str_ton: 'basis') {
    @return map-get(map-get($thm_arr_colors, $str_color_id), $str_ton);
}
// Breakpoints
// Z.B.: background-color: get_thm_col(basis, 85p);
@function get_bp($str_bp_name) {
    @if map-has-key($grid-breakpoints, $str_bp_name) {
        @return map-get($grid-breakpoints,$str_bp_name);
    }

    @warn "Unbekannter Index `#{$str_bp_name}` in Map $grid-breakpoints.";
    @return null;
}
// Spacers
// Z.B.: mb-4
@function get_bs_spacer($int_index) {
    @if map-has-key($spacers, $int_index) {
        @return map-get($spacers,$int_index);
    }

    @warn "Unbekannter Index `#{$int_index}` in Map $spacers.";
    @return null;
}


// Array: Themen-Kennungen (Bereichsabhängige Farb-Klassen)
$thm_arr_themen: (
    'basis',
    'basis_gelb',
    'abfall',
    'altlasten',
    'analytik_stoffe',
    'boden',
    'energie',
    'geologie',
    'klima',
    'laerm',
    'luft',
    'natur',
    'strahlung',
    'wasser'
);



// LfU Variablen /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BP-Container Breite
$thm_site_max_width:        1300px;
$thm_page_max_width:        1020px;

// Font-Stack
$thm_font_family:           Roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Bildunterschrift
$thm_bildunter_col:         get_thm_col(bildunterschrift);
$thm_bildunter_fs:         .9rem;

// Links
$thm_link_col_visited:      get_thm_col(link_visited);
$thm_link_col_funktion:     get_thm_col(link_funktion);

// Border-Color
$thm_border_col:            get_thm_col(border);

$h1_page_col:               get_thm_col(h1);
$h1_page_font_family:       $thm_font_family;
$h1_page_font_weight:       bold;

$h1_col:                    get_thm_col(h1);
$h1_font_family:            $thm_font_family;
$h1_font_weight:            bold;

$h2_col:                    get_thm_col(h2);
$h2_font_family:            $thm_font_family;
$h2_font_weight:            bold;

$h3_col:                    get_thm_col(h3);
$h3_font_family:            $thm_font_family;
$h3_font_weight:            bold;

$h4_col:                    get_thm_col(h4);
$h4_font_family:            $thm_font_family;
$h4_font_weight:            bold;

$h5_col:                    get_thm_col(h5);
$h5_font_family:            $thm_font_family;
$h5_font_weight:            bold;

$h6_col:                    get_thm_col(h6);
$h6_font_family:            $thm_font_family;
$h6_font_weight:            bold;

// Buttons
$thm_btn_bg:                get_thm_col(basis,18p);
$thm_btn_bg_hover:          get_thm_col(basis);
$thm_btn_col:               get_thm_col(basis);
$thm_btn_col_hover:         #fff;

// Link Fontawesome-Icons Unicodes
$li_intern: "\f340";
$li_extern: "\f343" ; 
$li_mail: "\f0e0"; 
$li_newin: "\f2d2"; 
$li_sprung: "\f07b";
$li_plus: "\2b"; 
$li_minus: "\f068";


// Default Bootstrap Variablen überschreiben /////////////////////////////////////////////////////////////////////////////////////////////////
// Theme Colors
$primary:                   get_thm_col(basis);
$secondary:                 get_thm_col(basis,18p);
$success:                   get_thm_col(basis_gruen);
$alert:                     get_thm_col(basis_rot);
$warning:                   get_thm_col(basis_gelb);


// Breadcrumb-Divider
$breadcrumb-divider:        quote(">");


// Überschriften
$font-size-base:            1rem;
$h1-font-size:              $font-size-base * 2.75;
$h2-font-size:              $font-size-base * 1.4;
$h3-font-size:              $font-size-base * 1.4;
$h4-font-size:              $font-size-base * 1.1;
$h5-font-size:              $font-size-base * 1.1;
$h6-font-size:              $font-size-base;


$tooltip-max-width:         400px;
$tooltip-color:             #fff;
$tooltip-bg:                get_thm_col(basis);
$tooltip-opacity:           1;

// Font-Stack
$font-family-sans-serif:    $thm_font_family;

$offcanvas-vertical-height: 89vh;

// Border-Radien
$enable-rounded: false;
// $border-radius:             0;
// $border-radius-sm:          0;
// $border-radius-lg:          0;
// $border-radius-xl:          0;
// $border-radius-2xl:         0;
// $border-radius-pill:        0;

// Links
$link-color:                get_thm_col(link);
$link-hover-color:          get_thm_col(link_hover);
$link-decoration:           none;


// Fontawesome ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$fa-font-path: "/global/lib/webfonts";


// Swiper /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$themeColor: get_thm_col(basis);


// Schrift-Definitionen ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$webfonts_path: '/global/lib/webfonts';
@font-face {
	font-family: 'Roboto';
	src: url($webfonts_path+'/Roboto-Regular.woff2');
    font-weight: 400;
}
@font-face {
	font-family: 'Roboto';
	src: url($webfonts_path+'/Roboto-Regular.woff2');
    font-weight: normal;
}
@font-face {
	font-family: 'Roboto';
	src: url($webfonts_path+'/Roboto-Medium.woff2');
    font-weight: 500;
}
@font-face {
	font-family: 'Roboto';
	src: url($webfonts_path+'/Roboto-Bold.woff2');
    font-weight: 700;
}