@charset "UTF-8";
 
// LfU-Header ////////////////////////////////////////////////////////////////////////////////////
body.home {
	.cmp_navbar { 
		margin-bottom: $bs_spacer_3; 
	}
}
.cmp_marker_header { 
	margin-bottom: $bs_spacer_3; 
	.cmp_navbar {
		margin-bottom: $bs_spacer_5;
		.cmp_wappen { 
			padding: $bs_spacer_2 $bs_spacer_3 0 0;
			@include media-breakpoint-up(lg) {
				padding-top: $bs_spacer_4;
			}
			@include media-breakpoint-up(xl) {
				padding-right: 0;
			}
			a {
				display: block;
			}
			img { 
				width: 65vw; max-width: 17rem; 
				@include media-breakpoint-up(sm) { max-width: 19rem; }
				@include media-breakpoint-up(md) { max-width: 22rem; }
				@include media-breakpoint-up(lg) { max-width: 23.5rem; }
			}
		 }
	}
	.cmp_marker { 
		position: relative; z-index: 1;
		img { 
			position: absolute; top: -1.5rem; width: 38vw; 
			@include global_box_shadow(); 
			@include media-breakpoint-up(sm) { max-width: 14rem; }
			@include media-breakpoint-up(md) { max-width: 16rem; }
			@include media-breakpoint-up(lg) { max-width: 18rem; }
		}
		img.circle { 
			position: absolute; top: -2.1rem; width: 20vw;
			@include global_box_shadow_round();
			@include media-breakpoint-up(sm) { top: -2.8rem; max-width: 9rem; }
			@include media-breakpoint-up(md) { top: -2.5rem; max-width: 10.2rem; }
			@include media-breakpoint-up(lg) { top: -2.6rem; margin-left: 3.4rem; max-width: 11.1rem; }
		}
	}
	.cmp_head_img { 
		overflow: hidden; position: relative; 
		// @include make-container();
		@include media-breakpoint-up(sm) { height: 30vw; }
		@include media-breakpoint-up(lg) { height: 27vw; }
		@include media-breakpoint-up(xl) { height: 20.4rem; }
		img {
			@include media-breakpoint-up(lg) {
				top: -1.5rem; position: relative;
			}
		}
		.credits { 
			position: absolute; 
			bottom: .2rem; 
			right: 1.5rem; 
			padding: 0 .2rem; 
			font-size: 2vw; 
			background-color: rgba(255, 255, 255, 0.5); 
			@include media-breakpoint-up(md) { font-size: .8rem; }
		}
		&.home .credits {
			display: none;
		}
	}
	&.small .cmp_navbar .cmp_wappen img { 
		width: 65vw; 
		max-width: 13rem; 
		@include media-breakpoint-up(sm) {
			max-width: 16rem;
		}
		@include media-breakpoint-up(md) {
			max-width: 18rem;
		}
		@include media-breakpoint-up(lg) {
			max-width: 20rem;
		}
	}
}



// Burger-Button ////////////////////////////////////////////////////////////////////////////////////
.cmp_burger { 
	padding: 0; 
	.btn { 
		padding: .6rem .8rem; 
		@include media-breakpoint-up(sm) { padding: .7rem 1.2rem; }
	}
	.btn .fa-bars { font-size: 2.5rem; }
	.btn .button_label { 
		display: none;
		margin-top: $bs_spacer_2;
		margin-left: $bs_spacer_2;
		vertical-align: top; 
		@include media-breakpoint-up(md) { display: inline-block; }
	}
}


// Custom Dropdown Icon
#cmp_topnav {
	.dropdown-toggle::after { display: none !important; }
	.nav-item a i { transform: translate(0,-30%) !important; }
}


// Homepage ////////////////////////////////////////////////////////////////////////////////////////
// Hero Image Text Animation
.cmp_head_img.home { 
	margin-bottom: $bs_spacer_5;
	position: relative; 
	div[id] { position: absolute; display: inline-block; opacity: .6; min-width: 65rem; font-size: 10vw; line-height: 7vw; font-weight: bold; color: #fff; @include global_text_shadow(); }
	#wasser { top: -1.5vw; left: 25vw; @include hero_img_ani(.75s); }
	#boden { top: 6vw; left: 65vw; @include hero_img_ani(1s); }
	#luft { top: 13vw; left: 15vw; @include hero_img_ani(1.25s); }
	#natur { bottom: 0; left: 40vw; @include hero_img_ani(1.5s); }
	@include media-breakpoint-up(xl) {
		div[id] { font-size: 7rem; line-height: 4.9rem; }
		#wasser { top: -18px; left: 300px; }
		#boden { top: 71px; left: 820px; }
		#luft { top: 156px; left: 180px; }
		#natur { bottom: -1px; left: 530px; }
	}
}


// Keine Breadcrumb auf der Homepage ////////////////////////////////////////////////////////////////
body.home .breadcrumb { display: none; }

//  Infoboxen
%cmp_box_base { position: relative; clear: both; padding: $bs_spacer_3 $bs_spacer_5 $bs_spacer_3 $bs_spacer_3; margin: 2.5rem 0 2rem 0; }
%cmp_box_icon { position: absolute; top: -19px; right: 0; font: var(--fa-font-sharp-regular); width: 45px; height: 45px; color: #fff; font-size: 1.5rem; line-height: 2.75rem; text-align: center; }
.cmp_box { 
	@extend %cmp_box_base;
	&.infobox { background-color: get_thm_col(basis_gelb,18p); }
	&.infobox::before { content: "\f129"; background-color: get_thm_col(basis_gelb); }
	&.fragebox { background-color: get_thm_col(basis,18p); }
	&.fragebox::before { content: "\3f"; background-color: get_thm_col(basis); }
	&.checkbox { background-color: get_thm_col(basis_gruen,18p); }
	&.checkbox::before { content: "\f00c"; background-color: get_thm_col(basis_gruen); }
	&.datebox { background-color: get_thm_col(basis,18p); }
	&.datebox::before { content: "\f133"; background-color: get_thm_col(basis); }
	&.praxisbox { background-color: get_thm_col(basis,18p); }
	&.praxisbox::before { content: "\f0ad"; background-color: get_thm_col(basis); }
	&.tippsbox { background-color: get_thm_col(basis,18p); }
	&.tippsbox::before { content: "\e2ca"; background-color: get_thm_col(basis); }
	&.warnbox { background-color: get_thm_col(basis_rot,18p); }
	&.warnbox::before { content: "\f071"; background-color: get_thm_col(basis_rot); }
	&.dontbox { background-color: get_thm_col(basis_rot,18p); }
	&.dontbox::before { content: "\f00d"; background-color: get_thm_col(basis_rot); }
}
.cmp_box::before { @extend %cmp_box_icon };



// Tables /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.cmp_content { 
	table { 
		width: 100%;
		clear: both;
		border-collapse: collapse;
		margin: 0 0 $bs_spacer_4 0;
		// font-size: 0.9rem;
		@include media-breakpoint-up(sm) { font-size: .85rem; }
		line-height: $bs_spacer_3;

		&.noautorow tr { background-color: white; }
		.align_right { text-align: right; }
		.align_center { text-align: center; }
		th { @include thm_table_th(); vertical-align: top; padding: $bs_spacer_3 $bs_spacer_2 0.3rem $bs_spacer_2; }
		tr {
			.tr_highlight { background-color: get_thm_col(basis_gelb,18p); }
			.tr_ok { background-color: get_thm_col(basis,18p); }
		}
		tr:nth-child(odd) { background-color: #eeeeee; }
		td { 
			vertical-align: top;
			// Responsive table
			padding: .4rem;
			@include media-breakpoint-up(sm) { padding: .2rem $bs_spacer_2; }
			border: 1px solid get_thm_col(basis,50p); 
			&:first-child { border-left: none; }
			&:last-child { border-right: none; }
			&.zeilenhead { padding: .3rem $bs_spacer_2 .2rem $bs_spacer_2; background-color: white; }
			&.zeilenhead h4 { color: black; padding: $bs_spacer_2 $bs_spacer_2 0 0; }
			h2, 
			h3,
			h4 { margin: 0; font-size: .85rem; }
		}
		caption { caption-side: bottom; padding: .3rem .6rem; color: get_thm_col(basis); border-bottom: 1px solid get_thm_col(basis,38p); }
		ul.linkliste li { margin-bottom: .2rem; }
	}
}
// Responsive Tables
.cmp_content {
	table .responsive_table_label { display: none; }
}
@media (max-width: get_bp('sm')) { 
	.cmp_content {
		table {
			thead,
			tbody,
			td { display: block; }
			.responsive_table_label { display: block; word-wrap: break-word; font-weight: bold; margin-bottom: $bs_spacer_1; }
			tr:first-of-type { display: none; }
			tr { overflow: auto; width: 100%; display: inherit; border-bottom: 2px solid get_thm_col(basis,50p); }
			td { min-height: 3.1rem; border-top: none; border-left: none; border-right: none; }
			caption { width: 100%; @include thm_table_th(); font-weight: bold; }
			.linkliste { display: inline-block; } 
			colgroup col { width: 100% }
		}    
	}
}



// Seiten Überschriftenbereich ////////////////////////////////////////////////////////////////////////////////////
.cmp_page_header { 
	@include container_padding();
	margin-bottom: $bs_spacer_5; 
	.cmp_breadcrumb { 
		min-height: 1.3rem;
		margin-bottom: $bs_spacer_3; 
		font-size: .85rem;
		ul { margin: 0; }
	}
	h1 { font-family: $h1_page_font_family; font-weight: $h1_page_font_weight; color: $h1_page_col; }
}


// Sidebars ////////////////////////////////////////////////////////////////////////////////////
.cmp_sidebar_left { 
	@extend .col; 
	@extend .col-lg-4; 
	@extend .order-1; 
	@extend .order-lg-0;
	@include media-breakpoint-up(lg) {
		padding-right: $bs_spacer_5;
	}
}
.cmp_sidebar_right { 
	@extend .col-lg-4;
	@include media-breakpoint-up(lg) {
		padding-left: $bs_spacer_5;
	}
}


// Navigation in aside ////////////////////////////////////////////////////////////////////////////////////
.cmp_nav_side { 
	margin-bottom: $bs_spacer_3; 
	@include media-breakpoint-up(lg) {
		margin-bottom: $bs_spacer_5;
	}
}


// Contentcontainer ////////////////////////////////////////////////////////////////////////////////////
.cmp_content
{
	&.left { 
		@extend .col; 
		@extend .col-lg-8;
	}
	&.right { 
		@extend .col-lg-8;
		@extend .order-0;
		@extend .order-lg-1;
		@include media-breakpoint-up(lg) {
			padding-left: 0;
		}
	}
}


// Label-Reiter (Farbig nach Bereich) ////////////////////////////////////////////////////////////////////////////////////
body .cmp_label_heading { @include heading_marke(); } 
// Abhängig vom Bereich
@each $str_color_id in $thm_arr_themen {
	body.#{$str_color_id} .cmp_label_heading {
		@include heading_marke_color($str_color_id);
	}
}
// Full width Label
.cmp_label_heading_full_width {
	clear: both;
	position: relative;
	margin-bottom: $bs_spacer_3;
	background-color: get_thm_col(basis,18p);
	height: 19px;
	h2 { 
		background-color: #526e7f;
		color: #fff;
		@include heading_marke();
	}
}
// Abhängig vom Bereich
@each $str_color_id in $thm_arr_themen {
	body.#{$str_color_id} .cmp_label_heading_full_width h2 {
		@include heading_marke_color($str_color_id);
	}
}


//  Carousel: Swiper ////////////////////////////////////////////////////////////////////////////////////
div[class^=swiper-button-]:hover { opacity: .6; }
// Aktuelles 
// FA-Next Icon
.cmp_swiper_wide .swiper-button-next::after,
.cmp_swiper.publ .swiper-button-next::after { content: '\f054'; font: var(--fa-font-sharp-regular); font-size: 2rem; font-weight: bold; }
// FA-Prev Icon
.cmp_swiper_wide .swiper-button-prev::after,
.cmp_swiper.publ .swiper-button-prev::after { content: '\f053'; font: var(--fa-font-sharp-regular); font-size: 2rem; font-weight: bold; }
.cmp_swiper_wide {
	// Required for overlapping Heading
	position: relative; overflow: visible;
	.swiper-slide { /* Required to hide next/prev slide */ background-color: get_thm_col(basis,18p); @include media-breakpoint-up(md) { min-height: 20rem; } }
	// Required to position next/prev buttons at bottom of swiper
	.swiper-button-next, .swiper-button-prev { top: initial; bottom: 0; }
	.swiper-button-prev { left: 0; }
	.swiper-button-next { right: 0; }
	// Height is required for display of only dots
	.divider { height: $bs_spacer_5; border-top: 2px solid get_thm_col(basis); }
	.crsl_titles { padding: .7rem; }
	// Position and style play/pause icon
	.crsl_pause_icon { position: absolute; bottom: .35rem; right: 2.4rem; z-index: 15; width: 5%; font-size: 1.5rem; font-weight: normal; color: get_thm_col(basis); cursor: pointer; }
	.crsl_pause_icon:hover { opacity: .6; }
	// Required to center bullet points vertically
	.swiper-pagination-bullets.swiper-pagination-horizontal { bottom: 12px; }
}
// Cards-Swiper 
.cmp_swiper_cards_big {
	// To avoid selecting, wenn user tries to grab on Text
	* { user-select: none; }
	// Required to position next/prev buttons at bottom of swiper
	.swiper-button-next, .swiper-button-prev { width: auto; height: 100%; top: 0; padding: 0 1rem; text-shadow: 0 -2px rgba(255, 255, 255, .85), 2px 0 rgba(255, 255, 255, .85), 0px 2px rgba(255, 255, 255, .85), -2px 0 rgba(255, 255, 255, .85), 0 0 10px white; }
	.swiper-button-prev { left: 0; }
	.swiper-button-next { right: 0; }
	.swiper-slide { background-color: #fff; /* To make all cards same height */ height: initial; }
}



// Compacts  ////////////////////////////////////////////////////////////////////////////////////
.cmp_compact { 
	position: relative; margin: 0; padding: 0; font-size: .9rem; 
	h1 { @include heading_marke_balken($bs_spacer_2); height: 1.8rem; font-size: 1rem; padding: .1rem 0 .2rem .8rem; }
	h2 { font-size: 1rem; line-height: 1.3rem; }
	h3 { font-size: .9rem; line-height: 1.1rem; }
	.list-group { overflow: hidden; margin-bottom: 0; }
	.list-group, 
	.list-group .list-group-item { border-color: rgb(192, 192, 192) !important; }
	.list-group li { line-height: 1.1rem; }
	.list-group p { margin-bottom: .2rem; color: #757575; }
}
// Abhängig vom Bereich
body .cmp_compact h1 { @include heading_marke_color(); }
@each $str_color_id in $thm_arr_themen {
	body.#{$str_color_id} .cmp_compact h1 {
		@include heading_marke_color($str_color_id);
	}
} 
aside .cmp_compact { margin-bottom: $bs_spacer_5; }
// Social Media
.cmp_compact.sm {
	i.fa-bluesky { font-size: 3.5rem; margin-top: .2rem; color: #0886fe; }
	i.fa-x-twitter { font-size: 4rem; }
	i.fa-whatsapp { font-size: 4rem; color: #1db437; }
	.item { margin: $bs_spacer_3 0; }
}


// List-Groups  ////////////////////////////////////////////////////////////////////////////////////
.cmp_list_group { 
	&.list-group { margin-bottom: $bs_spacer_4; } 
	.list-group-item { 
		padding: $bs_spacer_3 0; 
		.itemheader {
			@include make-row();
			padding-bottom: $bs_spacer_1; 
			.item {  
				@include make-col-ready(); 
				&.ni1 { @include make-col(12); }
				&.ni2 { @include make-col(6); }
				&.ni3 { @include make-col(4); }
			}
		}
		small{ color: get_thm_col(basis); }
		a { display: block; }
		p { margin-bottom: 0; }
	}
}

.tab-pane .cmp_list_group.list-group { margin-bottom: 0; }
aside .cmp_list_group { margin-bottom: $bs_spacer_2; }
.cmp_compact .cmp_list_group .list-group-item { padding: $bs_spacer_2 0; }


// Responsive Cols im Content ////////////////////////////////////////////////////////////////////////////////
.cmp_resp_cols {
	@include make-row();
	.item {
		@include make-col-ready();
		@include make-col(12);
	}
	&.c2.order_reversed {
		.item:first-of-type { @extend .order-2; @extend .order-md-1; }
		.item:last-of-type { @extend .order-1; @extend .order-md-2; } 
	}
	&.c2 .item {
		@include media-breakpoint-up(sm) { @include make-col(6); }
	}
	&.c3 .item {
		@include media-breakpoint-up(sm) { @include make-col(6); }
		@include media-breakpoint-up(md) { @include make-col(4); }
	}
	&.c4 .item {
		@include media-breakpoint-up(sm) { @include make-col(6); }
		@include media-breakpoint-up(md) { @include make-col(4); }
		@include media-breakpoint-up(lg) { @include make-col(3); }
	}
	&.c5 .item {
		@include media-breakpoint-up(sm) { @include make-col(6); }
		@include media-breakpoint-up(md) { @include make-col(4); }
		@include media-breakpoint-up(lg) { width: 20%; }
	}
	&.c6 .item {
		@include media-breakpoint-up(sm) { @include make-col(6); }
		@include media-breakpoint-up(md) { @include make-col(3); }
		@include media-breakpoint-up(lg) { @include make-col(2); }
	}
}


// Consent-Layer / Embedding
.cmp_consent_layer {
	margin: $bs_spacer_4 0 $bs_spacer_2 0;
	padding: $bs_spacer_3;
	background-color: get_thm_col(basis,18p);
	.fa-brands {
		float: left; padding-right: $bs_spacer_3; font-size: 5rem; 
		&.fa-youtube { color: #FF0000; }
	}
	ul { margin-bottom: 0; }
}
.cmp_embed_iframe { margin: $bs_spacer_3 0; }




// HP Elementecontainer  ////////////////////////////////////////////////////////////////////////////////////
.cmp_hp_elem { 
	margin-bottom: $bs_spacer_5;
	&.bg { background-color: get_thm_col(basis,18p); }
	// Twitter
	.twitter col .twitter { height: 430px; }
	.twitter_tl {
		.twitter-timeline { max-height: 350px; overflow-y: scroll; overflow-x: hidden; }
		.spinner { min-height: 350px; }
		// TWITTER 
		// .twitter-timeline .consent_layer .fa-brands { float: left; margin-right: .75rem; font-size: 3rem; color: #1D9BF0; }
		// Umstellung auf X
		.twitter-timeline { height: 350px; }
		.x_hack {
			height: 200px; overflow: hidden;
			.x_logo { margin-top: -.2rem; float: left; margin-right: .75rem; font-size: 8rem; } 
		}
	}
}


// Doppelbox, die initial ins Bild slidet  ////////////////////////////////////////////////////////////////////////////////////
.cmp_doppelbox_slide { position: relative; }


// Rechte Spalte  ////////////////////////////////////////////////////////////////////////////////////
// Publikationen
.cmp_swiper.publ .swiper-pagination { position: relative; }
.cmp_swiper.publ h2 { font-size: .95rem; }


// Publikationen -> Libraries
.cmp_compact.publikationen { 
	text-align: center; 
	h1 { text-align: left; }
	a { display: block; }
	p { padding-top: .3rem; }
}


// Teaserblöcke
.cmp_th_einleitung { margin-bottom: $bs_spacer_4; }
.cmp_teaser_block { 
	margin-bottom: $bs_spacer_5;
	.teaser_block {
		@include make-row();
		padding-bottom: $bs_spacer_3;
	}
	.item_img {
		padding-bottom: $bs_spacer_2;
		@include make-col-ready(); 
		@include make-col(12);
		@include media-breakpoint-up(sm) { @include make-col(4); }
		@include media-breakpoint-up(md) { @include make-col(3); }
		@include media-breakpoint-up(lg) { @include make-col(4); }
		img { margin-bottom: 0.2rem; }
		p { @include p_bildunterschrift_klein(0); }
	}
	.item_content {
		@include make-col-ready(); 
		@include make-col();
	}
	.date { margin: $bs_spacer_2 0; font-size: 1rem; color: get_thm_col(basis); }
	.btn { margin-bottom: $bs_spacer_2; }
	h3 { font-size: 1.2rem; font-weight: bold; }

	&.publikationen img { max-width: 150px; max-height: 220px; }
	&.dienste img { max-width: 140px; }

	&.gross { 
		padding-top: $bs_spacer_2; 
		.item_img { 
			@include make-col-ready();
			@include make-col(12);
			@include media-breakpoint-up(sm) { @include make-col(4); }
			@include media-breakpoint-up(lg) { @include make-col(6); }
			img { max-width: 100%; height: auto; }
		}
	}
}
.cmp_teaser_block h2 { @include heading_marke_balken(1.2rem); @include heading_marke_color('basis_gelb'); padding: .2rem .8rem; }
@each $str_color_id in $thm_arr_themen {
	body.#{$str_color_id} .cmp_teaser_block h2 {
		@include heading_marke_color($str_color_id);
	}
}


h2.label_heading { @include heading_marke_label(); }
h2.label_heading::after { @include heading_marke_pseudo(); }
body h2.label_heading { @include heading_marke_color(); }
// Abhängig vom Bereich
@each $str_color_id in $thm_arr_themen {
	body.#{$str_color_id} h2.label_heading {
		@include heading_marke_color($str_color_id);
	}
}



// Bereich-Themenbalken
.cmp_th_balken h2 { @include heading_marke_balken(.8rem); padding: .2rem .8rem; margin-bottom: 0; color: #fff; }



// Fontawesome-Icon als Marginale /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
p i.initiale { @include p_fa_icon(); }


// Einfache Listen /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.cmp_content {
	ul:not([class]) { list-style: none; padding-left: $bs_spacer_5; }
	ul:not([class]) li:before { display: inline-block; width: 1.2rem; margin-left: -1.5rem; font: var(--fa-font-sharp-regular); content: "\e27e"; color: get_thm_col(basis,85p); }
	ul:not([class]) li ul:not([class]) { padding-left: 1.5rem; }
	ul:not([class]) li ul:not([class]) li:before { color: get_thm_col(basis,50p); }
	ul:not([class]) li ul:not([class]) li ul:not([class]) li:before { font: var(--fa-font-sharp-regular); content: "\f0c8"; font-size: .7rem; position: relative; top: -1px; }
	ul:not([class]) li ul:not([class]) li ul:not([class]) li ul:not([class]) li:before { font-size: .5rem; }
	ol > li::before { content: "" !important; }
}


// Buttons /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Standard
.btn.thm { @include thm_btn_col(); }
.btn.thm.rahmen { @include thm_btn_col_rahmen(); }
.btn.thm:hover,
.btn.thm.active { @include thm_btn_col_hover(); }
// Invers
.btn.thm_inv { @include thm_btn_col_hover(); }
.btn.thm_inv.rahmen { @include thm_btn_col_rahmen_invers(); } 
.btn.thm_inv:hover,
.btn.thm_inv.active { @include thm_btn_col(); }



// Tooltipps /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.tooltip-inner { text-align: left; }


// Formulare /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
label { font-weight: bold; }
.form_validate_error {font-weight: bold; color: get_thm_col(basis_rot, basis);}


// Blockquote /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
blockquote { 
	@extend %cmp_box_base; background-color: get_thm_col(basis,18p);
	&::before { @extend %cmp_box_icon; content: "\f10e"; background-color: get_thm_col(basis); }
	p, ul { margin-bottom: 0; }
	cite { font-size: .9rem; }
}

// Fußnote /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.footnote { 
	@extend %cmp_box_base; background-color: get_thm_col(basis,18p);
	&::before { @extend %cmp_box_icon; content: "\f10e"; background-color: get_thm_col(basis); }
	p, ul { margin-bottom: 0; }
	cite { font-size: .9rem; }
}


// Hover: Undeline
.cmp_content a:hover:not(.nounderline):not(.btn):not([data-gallery]),
.sidebar a:hover:not(.nounderline):not(.btn):not([data-gallery]),
.list-group-item a:hover { background-size: 100% 1px; }
// Lupe: Vergößerbares Bild
div[class^=content_zoom_] a[class="link_extern"]::before { @include linkicons_pseudo("\f08b"); content: "\f08b"; }

// Linklisten
main {
	ul.linkliste {
		clear: both; list-style: none;
		li { margin-left: -30px; list-style-image: none; background-repeat: no-repeat; background-size: $bs_spacer_3; padding-left: 1.5em; background-position: 0 3px; }
		li.intern { background-image: url(/global/lib/fontawesome_svg/arrow-right-to-line-sharp-regular.svg); }
		li.extern { background-image: url(/global/lib/fontawesome_svg/arrow-right-from-line-sharp-regular.svg); }
		li.mail { background-image: url(/global/lib/fontawesome_svg/envelope-sharp-regular.svg); }
		li.newin { background-image: url(/global/lib/fontawesome_svg/window-restore-sharp-regular.svg); }
		li.sprung { background-image: url(/global/lib/fontawesome_svg/arrow-down-to-line-sharp-regular.svg); }
		li.show { background-image: url(/global/lib/fontawesome_svg/plus-sharp-regular.svg); background-size: .85rem; }
		li.hide { background-image: url(/global/lib/fontawesome_svg/minus-sharp-regular.svg); background-size: .85rem; }
		li.file { background-image: url(/global/lib/fontawesome_svg/folder-sharp-regular.svg); background-size: .85rem; }
	} 
	table ul.linkliste li { background-size: .85rem; background-position: 1px 1px; }
} 



// Bilder
div.content_zoom_img,
div.content_zoom_img_links,
div.content_zoom_img_rechts { display: block; }
img.rahmen { outline: 1px solid #c0c0c0; outline-offset: -1px; }
// Vergrößerbares Content-Bild mit Legende
div[class^=content_zoom_] { position: relative; overflow: hidden; width: 292px; margin-bottom: 1.2rem; margin-top: 0.3rem; font-size: $thm_bildunter_fs; color: $thm_bildunter_col; hyphens: auto; line-height: 1.1rem; }
div[class^=content_zoom_] img { width: 292px; height: auto; margin-bottom: .6rem; transition: transform .5s ease-in-out; }
div[class^=content_zoom_] a img { transition: all 250ms ease-in-out; }
div[class^=content_zoom_] a img:hover { opacity: .9; }
div[class^=content_zoom_] a[title^='Bild '] { display: block; }
div[class^=content_zoom_] a[title^='Bild ']::after { position: absolute; top: 16px; right: 16px; text-rendering: auto; -webkit-font-smoothing: antialiased; content: "\f002"; font: var(--fa-font-sharp-regular); font-size: 1.6rem; color: rgba(255, 255, 255, .85); text-shadow: 0 0 3px rgb(75, 75, 75); }
div[class^=content_zoom_] + p { margin-top: 0; }
div[class^=content_zoom_] p { margin-bottom: 0; }
div.content_zoom_img_seitenbreite { width: 100%; max-width: 650px; padding-top: 0; }
div.content_zoom_img_seitenbreite img { display: block; width: 100%; }
div.content_zoom_img_doppelunterschrift { width: 100%; }
div.content_zoom_img_doppelunterschrift div { margin-bottom: 0; }
div.content_zoom_img_doppelunterschrift p { clear: both; }
div.content_zoom_img_rechts { float: none; }
div.content_zoom_img_links + .content_zoom_img_rechts + p,
div.content_zoom_img_links + .content_zoom_img_rechts + .content_zoom_img_links,
div.content_zoom_img_doppelunterschrift + p { clear: both; }
div.content_zoom_img, 
div.content_zoom_img_links { margin-right: 2.9em; }
@media (min-width: get_bp('md')) { .content_zoom_img_rechts { float: right; } }
@media (min-width: get_bp('sm')) { div.content_zoom_img, div.content_zoom_img_links { float: left; } div.content_zoom_img_rechts { float: right; } }



// Weiterführende Infos
.content_more_info { clear: both; margin: $bs_spacer_5 0 $bs_spacer_5 0; padding: $bs_spacer_3 0 $bs_spacer_3 0; border-top: 1px solid get_thm_col(basis,85p); border-bottom: 1px solid get_thm_col(basis,85p); }
.content_more_info h2 { margin-bottom: $bs_spacer_3; }
.content_more_info h3 { display: none; }
.content_more_info ul { margin-bottom: 0; }
.content_more_info li { list-style-type: none; }



// DL-Liste
// dl { margin-bottom: $bs_spacer_; @extend .row-cols-1; @extend .row-cols-md-2; }
// dt { margin-bottom: 0; border-bottom: none; }
// dd { padding-bottom: 0.5rem; border-bottom: 1px solid; @include thm_border_col(); }
// @media (min-width: get_bp('md')) { 
//     dt { margin-bottom: 0.5rem; border-bottom: 1px solid; @include thm_border_col(); padding-bottom: 0; }
// }






// Lightbox-Elements /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.cmp_btn_nav { margin-bottom: 2rem; }
.cmp_btn_nav .btn { margin-bottom: .25rem; min-width: 2.8rem; } 



// Lightbox-Elements /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.lightbox-caption { background-color: #000; }


// Tooltipp Grafik mit Imagemap /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.imagemap_tt_container { 
	position: relative; 
	#im_tooltip { position: absolute; overflow: visible; width: 170px; display: none; background-color: #fff; }
	#im_tooltip.in { display: block; animation: zoomIn .3s; }
	#im_tooltip.out { display: none; animation: zoomOut .3s; }
	#im_tooltip.card { font-size: .95rem; line-height: 1.1rem; z-index: 2; }
	#im_tooltip.card p { margin: 0; }
	#im_tooltip .card-title { margin: 0; padding: $bs_spacer_2; color: #fff; font-weight: bold; }
	#im_tooltip .card-body { padding: $bs_spacer_2; }
	.collapse:not(.show) { display: block; @include media-breakpoint-up(md) { display: none; } }
}





// Tabs
.tab-pane .card { border-top: none; }


// Accordion
.accordion { margin: 0 0 $bs_spacer_3 0; clear: both; }
.accordion-button { font-weight: bold; }
.accordion-body { 
	overflow: hidden;
	h3 { font-size: 1.2rem; }
	h4 { font-size: 1.1rem; }
}
.filter_options { margin: $bs_spacer_2 0; }

//  Cards
.cmp_cards {
	.card h2, .cmp_cards .card h3 { font-size: 1.1rem; }
	.item { padding-bottom: $bs_spacer_4; }
	.card * { color: black; }
	.card-body p { @include p_bildunterschrift_klein(0); }
	.card-footer a { display: block; }
	.card-footer { background-color: get_thm_col(basis,18p); border-top: none; }
	.card_img_wrapper { overflow: hidden; }
	// Animation
	.card_img_wrapper img { @include card_hover_transition(); }
	.card:hover img { @include card_hover_scale(); }
	// Themen Kacheln
	&.img_list * { user-select: none; }
	&.img_list { 
		padding-left: 0; list-style-type: none; 
		.card { 
			background-color: transparent; overflow: hidden; 
			img { transition: all 250ms ease-in-out; }
			img:hover { opacity: .9; }
			h2, h3 { font-size: 100%; }
		}
	}
}

// Themen-Liste
.cmp_th_list { display: block; padding: 0 0 0 $bs_spacer_2; line-height: 1.5rem; border-left: solid $bs_spacer_2; }



//  Social Media
.cmp_sm_icons { 
	clear: both; position: relative; margin: $bs_spacer_5 0; background-color: get_thm_col(basis,18p); 
	h2 { @include heading_marke(); }
	
	.cmp_shariff_icons
	{
		padding: 0 $bs_spacer_4 $bs_spacer_2 $bs_spacer_4;
		&.shariff { 
			.orientation-horizontal.button-style-standard li { min-width: initial; }
			.theme-white {
				.shariff-button {
					border: none;
					a { background-color: transparent; background-image: none; }
					a:hover { background-color: get_thm_col(basis); color: get_thm_col(basis,18p); }
					a:hover .share_text {  color: get_thm_col(basis,18p); }
				}
			}
			li a .share_text { font-family: inherit; font-size: inherit; color: #000; }
		}
	}
	// Hack für X-Icon
   .shariff-button.bluesky .fab { color: #0886fe; font-size: 1.2rem; }
   .shariff-button.twitter .fab { color: #000; font-size: 1.35rem; }
   .shariff-button.mail .fab { padding: 0 .6rem 0 .2rem; font-size: 1.35rem; }
   .shariff-button.twitter:hover .fab { color: #fff; }
}
body .cmp_sm_icons h2 { @include heading_marke_color(); }
// Abhängig vom Bereich
@each $str_color_id in $thm_arr_themen {
	body.#{$str_color_id} .cmp_sm_icons h2 {
		@include heading_marke_color($str_color_id);
	}
} 



// Countdown
#countdown {
	min-height:80px;
	overflow: hidden; 
}

#countdown > div {
	color: #fff;
}

#countdown_completed {
	font-size: 2em;
}

// Adjust CMU Forms
.multiselect-wrapper {
	.multiselect-input-div input {display:inline-block;}
	.multiselect-input-div {height:auto;}
	.multiselect-list ul li:before {content:"";}
	.multiselect-list ul { padding-left: 0;}
}


