@charset "UTF-8";

// Container Padding/Margin
@mixin container_padding() {
	padding: 0 $bs_spacer_4;

	@include media-breakpoint-up(lg) {
		padding: 0;
	}
}

// Links außerhalb Textkörper
@mixin funktionslink_a() {
	display: block;
	color: $thm_link_col_funktion;
}

@mixin funktionslink_hover() {
	color: $link-hover-color;
}

// Buttons
@mixin thm_btn_col() {
	background-color: $thm_btn_bg;
	color: $thm_link_col_funktion;
}

@mixin thm_btn_col_hover() {
	background-color: $thm_btn_col;
	color: $thm_btn_col_hover;
}

@mixin thm_btn_col_rahmen() {
	border: 1px solid;
	background-color: $thm_btn_bg;
}

@mixin thm_btn_col_invers() {
	background-color: $thm_btn_col;
	color: $thm_btn_col_hover;
}

@mixin thm_btn_col_hover_invers() {
	background-color: $thm_btn_bg;
	color: $thm_link_col_funktion;
}

@mixin thm_btn_col_rahmen_invers() {
	border: 1px solid;
	background-color: $thm_btn_col;
}

// Borders
@mixin thm_border_col() {
	border-color: $thm_border_col;
}

// Überschriften als Label
@mixin heading_marke() {
	display: inline-block;
	margin-bottom: 0;
	transform: translateY(-40%);
	padding: 0 $bs_spacer_3;
	font-size: 1.1rem;
	line-height: 2rem;
	font-weight: bold;
	z-index: 5;
	width: auto;
	height: auto;
}
@mixin heading_marke_balken($margin-bt: 0.9rem) {
	margin-bottom: $margin-bt;
	font-size: 1.1rem;
	line-height: 1.5rem;
}
@mixin heading_marke_label() {
	display: flex;
	align-items: center;
	overflow: hidden;
	margin-bottom: 0.9rem;
	padding-left: $bs_spacer_3;
	font-size: 1.1rem;
	line-height: 2rem;
}
@mixin heading_marke_pseudo() {
	content: "";
	flex: auto;
	margin-left: $bs_spacer_3;
	border-top: 17px solid #fff;
	border-bottom: 17px solid get_thm_col(basis, 18p);
}
@mixin heading_marke_color($bereich: "basis_gelb", $color: #fff) {
	background-color: get_thm_col($bereich);
	color: $color;
}

// Cards-Hover-Effect
@mixin card_hover_transition() {
	transition: all 0.2s ease-in-out;
}
@mixin card_hover_scale() {
	transform: scale(1.07);
}

// Shadow
@mixin global_box_shadow($x: 0, $y: 0) {
	box-shadow: $x $y 20px rgba(0, 0, 0, 0.3);
}
@mixin global_box_shadow_round($x: 0, $y: 0) {
	border-radius: 50%;
	@include global_box_shadow();
}
@mixin global_text_shadow($x: 0, $y: 0, $b: 4px) {
	text-shadow: $x $y $b rgba(0,0,0,0.3);
}

// Pseudoklassen-Linkziele
@mixin linkicons_pseudo($padding_left: 0, $padding_right: 0.6rem) {
	padding-left: $padding_left;
	padding-right: $padding_right;
	font: var(--fa-font-sharp-regular);
	color: get_thm_col(basis, 85p);
}

// Tables
@mixin thm_table_th() {
	padding: 0.3rem $bs_spacer_2;
	border: 1px solid get_thm_col(basis, 50p);
	color: white;
	background-color: get_thm_col(basis);
	vertical-align: top;
}

// Hero-Image-Animation
@mixin hero_img_ani($animation_delay) {
	animation-duration: 1s;
	animation-delay: $animation_delay;
	animation-fill-mode: both;
}

// Icon am Textanfang
@mixin p_fa_icon() {
	float: left;
	margin-right: 0.75rem;
	margin-top: 0.3rem;
	font-size: 3rem;
	color: get_thm_col(basis_gelb);
}

// Bildunterschrift
/* @mixin p_bildunterschrift($margin_bottom: 1rem) {
	margin-bottom: $margin_bottom; font-size: 0.9rem; color: #4d4d4d; line-height: 1.1rem;
} */
@mixin p_bildunterschrift_klein($margin_bottom: 1rem) {
	margin-bottom: $margin_bottom;
	font-size: 0.8rem;
	color: #686868;
	line-height: 1.1rem;
}
